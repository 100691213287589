import React from "react";
import {useTranslation} from "react-i18next";
import {Heading} from "../../../common/heading";
import {Section} from "../../../common/section";

export const Specifications: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Section>
      <h2>{t('products.common.specifications.heading')}</h2>
      <table>
        <tbody>
        <tr>
          <td><strong>Voltage</strong></td>
          <td>230V/72</td>
          <td>✓</td>
          <td rowSpan={3}>Auto Program <br/>
            <br/>
            Safe System<br/>
            <br/>
            Bluetooth<br/>
            <br/>
            Battery unit<br/>
          </td>
        </tr>
        <tr>
          <td><strong>Capacity (w)</strong></td>
          <td>3200</td>
          <td>✓</td>
        </tr>
        <tr>
          <td><strong>UV-C Radiation</strong></td>
          <td>3200</td>
          <td>✓</td>
        </tr>
        </tbody>
      </table>
      {/*<Heading level={1}>{t('products.skeptron.skeptron_l.introduction.heading')}</Heading>*/}
      {/*<p>{t('products.skeptron.skeptron_l.introduction.description')}</p>*/}
      {/*<button>{t('products.skeptron.skeptron_l.introduction.button')}</button>*/}
    </Section>
  )
}
