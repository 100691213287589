import React from "react";
import {NormalLayout} from "../../../components/layouts/normal_layout";
import {Introduction} from "../../../components/products/skeptron/skeptron_r/introduction";
import {Benefits} from "../../../components/products/skeptron/skeptron_r/benefits";
import {Details} from "../../../components/products/skeptron/skeptron_r/details";
import {Applications} from "../../../components/products/skeptron/skeptron_r/applications";
import {Specifications} from "../../../components/products/skeptron/skeptron_r/specifications";
import {SEO} from "../../../components/seo";

const SkeptronR: React.FC = () => {

  return (
      <NormalLayout type="product">
        <SEO title="Skeptron R | UV Tech by Rendev" description="Skeptron R | UV Tech by Rendev" />
        <Introduction />
        <Benefits />
        <Details />
        <Applications />
        <Specifications />
      </NormalLayout>
  )
}

export default SkeptronR;