import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../../../common/section";
import {Badges} from "../../../common/badges";
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from "gatsby-image";

export const Benefits: React.FC = () => {
  const {t} = useTranslation();

  const items = [1, 2, 3, 4];

  const data = useStaticQuery(graphql`
  query {
  benefits: file(relativePath: {eq: "skeptron/Skeptron-R-normal.png"}) { ...squareImage } 
  }`)

  return (
    <Section className="benefits">
      <div className="intro-text">

        {/*<h2>{t('products.skeptron.skeptron_r.benefits.heading')}</h2>*/}
        <div className="text">
          <p>{t('products.skeptron.skeptron_r.benefits.description')}</p>
        </div>
      </div>

      <div>
        <Badges/>
      </div>

      <div className="advantages">

        <div className="image">
          <GatsbyImage
            style={{height: "100%", width: "100%"}}
            fluid={data.benefits.childImageSharp.fluid}
          />
        </div>

        <div className="text">


          <h2>{t('products.skeptron.skeptron_r.benefits.subsection.heading')}</h2>
          <p>{t('products.skeptron.skeptron_r.benefits.subsection.description')}</p>
          <ul>
          {
            items.map((item, index) => {
              return (
                <li key={index} className={t(`products.skeptron.skeptron_r.benefits.items.${index}.class`)}>
                  <h4>{t(`products.skeptron.skeptron_r.benefits.items.${index}.heading`)}</h4>
                  <p className="small">{t(`products.skeptron.skeptron_r.benefits.items.${index}.description`)}</p>
                </li>
              )
            })
          }
          </ul>
        </div>
      </div>

    </Section>
  )
}
