import React from "react";
import {useTranslation} from "react-i18next";
import {Heading} from "../../../common/heading";
import {PromoSection} from "../../../common/promo_section";
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from "gatsby-image";

export const Introduction = () => {
  const {t} = useTranslation();

  const data = useStaticQuery(graphql`
  query {
  promo: file(relativePath: {eq: "promo/Skeptron-R-promo.png"}) { ...promoImage } 
  }`)

  return (
    <PromoSection>
      <div className="page-promo-text">
        <Heading level={1}>{t('products.skeptron.skeptron_r.introduction.heading')}</Heading>
        <p>{t('products.skeptron.skeptron_r.introduction.description')}</p>
        <a className="button" href={`/Brochure-Skeptron-R.pdf`}>{t('products.skeptron.skeptron_r.introduction.button')}</a>
      </div>
      <div className="page-promo-image">
        <div className="image-holder">
          <div className="image">
            <GatsbyImage
              style={{height: "100%", width: "100%"}}
              fluid={data.promo.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </PromoSection>
  )
}
